import React from "react";

import { Breadcrumb, Container, CeoMessageContents } from "../../components";
import Seo from "../../components/Seo";

const CeoMessagePage = () => {
  return (
    <Container>
      <Seo
        title="代表メッセージ"
        description="TKCホールディング代表取締役 長谷川千寛からのメッセージをご覧いただけます。"
      />
      <CeoMessageContents />
      <Breadcrumb
        currentPage="CEO Message"
        currentSlug="/company/ceo-message"
        parentPage="Company"
        parentSlug="/company"
      />
    </Container>
  );
};

export default CeoMessagePage;
